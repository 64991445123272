<template>
  <MainLayout>
    <template v-slot:navbar-title>Группы объектов</template>
    <template v-slot:default>
      <TableFilters :queries="queries" :loading="isDateLoaded">
        <template v-slot:add-button>
          <router-link
            to="createServiceGroup"
            class="btn btn-add btn_color_green"
          >
            Добавить группу объектов
          </router-link>
        </template>
        <template v-slot:filter-title>Всего: {{ sourceObject.count }}</template>
        <template v-slot:download-button>
          <v-btn
            @click="downloadList"
            class="btn btn_color_grey btn-unload flex-right mr-4"
            :disabled="loadingBtn || sourceObject.results.length == 0"
            :loading="loadingBtn"
          >
            Выгрузить
          </v-btn>
        </template>
      </TableFilters>
      <TableLayout
        :loading="isDateLoaded"
        :table-data="sourceObject.results"
        :header="header"
        :source-object="sourceObject"
      />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";

export default {
  name: "ServiceGroups",
  data() {
    return {
      loadingBtn: false,
      queries: query.serviceGroups,
      header: [
        {
          text: "Название",
          value: "name",
          renderFunction: value => {
            return value.name
              ? value.name
              : `<div>Группа объектов ${value.id}<div>`;
          }
        },
        {
          text: "Объектов",
          value: "object_count",
          src: "/img/table_icons/tabbar__icon_3.svg",
          width: 20,
          sort: true
        }
      ]
    };
  },
  components: {
    TableFilters,
    TableLayout,
    MainLayout
  },
  computed: {
    sourceObject() {
      return this.$store.getters.getPageServiceGroupsList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageServiceGroupsList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadServiceGroupsList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageServiceGroupsList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearServiceGroupsPage");
    next();
  }
};
</script>

<style scoped></style>
